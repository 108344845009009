/*
 * エラーメッセージ
 */
export const DEFAULT_ERROR_MESSAGE =
  'エラーが発生しました。しばらく経ってから再度お試しください';
export const SESSION_EXPIRED_MESSAGE =
  'セッションの有効期限が切れています。再度ログインを行なってください';
export const LOGIN_ERROR_MESSAGE =
  'メールアドレスまたはパスワードが正しくありません。ご確認の上、再度ご入力ください';

/*
 * バリデーションメッセージ
 */
export const factoryRequiredErrorMessage = (fieldName: string): string => {
  return `${fieldName}を入力してください。`;
};
export const factoryRequiredForSelectErrorMessage = (
  fieldName: string,
): string => {
  return `${fieldName}を選択してください。`;
};
export const factoryHalfSizeNumberErrorMessage = (
  fieldName: string,
): string => {
  return `${fieldName}は半角数字で入力してください`;
};
export const factoryStringErrorMessage = (fieldName: string): string => {
  return `${fieldName}は文字で入力してください`;
};
export const factoryBooleanErrorMessage = (fieldName: string): string => {
  return `${fieldName}に指定できない値が選択されています`;
};
export const factoryMinNumberErrorMessage = (
  fieldName: string,
  min: number,
): string => {
  return `${fieldName}は${min}以上で入力してください`;
};
export const factoryMaxNumberErrorMessage = (
  fieldName: string,
  max: number,
): string => {
  return `${fieldName}は${max}以下で入力してください`;
};
export const factoryMinLengthErrorMessage = (
  fieldName: string,
  min: number,
): string => {
  return `${fieldName}は${min}文字以上で入力してください`;
};
export const factoryMaxLengthErrorMessage = (
  fieldName: string,
  max: number,
): string => {
  return `${fieldName}は${max}文字以下で入力してください`;
};
export const factoryEmailFormatErrorMessage = (fieldName: string): string => {
  return `${fieldName}は正しい形式で入力してください`;
};
